import { get, put, post, del } from "./base";
//获取分组
export function getGroup(type, pageNo, pageSize) {
  return get(
    `/conferenceAffairs/member/group?type=${type}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
}
// 添加分组
export function addGroup(data) {
  return post("/conferenceAffairs/member/group", data);
}
// 删除
export function delGroup(code) {
    return del(`/conferenceAffairs/member/group/${code}`);
}
// 修改
export function editGroup(data) {
    return put("/conferenceAffairs/member/group",data);
}
// 添加成员
export function addMember(data) {
    return post('/conferenceAffairs/task/member',data)
}
// 获取成员
export function getMember(data) {
   return get('/conferenceAffairs/task/member',data)   
}
// 删除成员
export function delMember(id) {
     return del(`/conferenceAffairs/task/member/${id}`)
}
//修改成员;
export function editMember(data) {
    return put('/conferenceAffairs/task/member',data)
}
